.pageContainer {
    font-family: "Gilroy-Regular" !important; 
    max-width: 100%;
    margin: 0 auto;
    margin-top: 70px !important;
    padding: 16px;
    background-color: #161616;
    overflow: hidden !important;
    position: relative !important;
    min-height: 100vh;
}


.goodsListContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
}

.modelText {
    font-family: "Gilroy-Regular" !important; 
    color: #F3F3F3 !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    -webkit-font-weight: 600 !important;
    text-align: left !important;
    margin-bottom: 10px !important;
}
