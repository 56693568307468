.header {
    background-color: #202020 !important;
    overflow: hidden;
}

header p {
    font-family: "Gilroy-Regular" !important; 
    font-weight: 500 !important;
    letter-spacing: 0px !important;
}
  
.toolbar {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.runningText {
    background: linear-gradient(98.38deg, #E8C900 -5.95%,#EDAB00 99.21%);
    display: flex;
  }
  
  .runningTextContent {
    font-family: "Gilroy-Regular" !important; 
    font-weight: 800 !important;
    color: #F3F3F3;
    line-height: 20px !important;
    font-size: 12px !important;
    letter-spacing: 0.5px !important;
    padding-right: 30px;
    padding-left: 30px;
    white-space: nowrap;
    animation: marquee 8s linear infinite;
}


@keyframes marquee {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.fullscreenDrawer {
    width: 100% !important;
    height: calc(100vh - 76px) !important;
    top: 76px !important;
    background-image: url("../../assets/images/MenuBackground.png");
    align-content: center !important;
    justify-content: right !important;
    align-items: center !important;
    font-size: 32px !important;
}

.menuDrawer {
    background-image: url("../../assets/images/MenuBackground.png");
    color: #F3F3F3;
    height: 100%;
    width: 100% !important;
    display: flex !important;
    flex-direction: column;
    justify-content: space-between !important;
    align-items: flex-end !important;
    align-self: flex-end;
}

.menuDrawer ul {
    width: 100% !important;
}

.menuDrawer span {
    font-family: "Gilroy-Regular" !important;
    font-weight: 600 !important;
    text-align: right;
    font-size: 30px; 
    margin-top: 20px;
    margin-bottom: 20px;
    letter-spacing: 0.5px !important;
}

.menuDrawer p {
    font-weight: 600 !important;
    letter-spacing: 0.5px !important;
}

.selectedMenuItem div {
    display: flex !important;
    justify-content: right;
}

.selectedMenuItem span {
    font-family: "Gilroy-Bold" !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent;
    font-size: 32px;
    letter-spacing: -0.5px !important;
    background: -webkit-linear-gradient(328deg, #E8C900 4.27%, #ED8000 139.4%);
}

.priceListButton {
    padding-left: 15px;
    max-width: 25%;
}

@media (max-width: 370px) {
    .priceListButton {
        padding-left: 5px;
    }
}

@media (min-width: 370px) {
    .priceListButton {
        padding-left: 15px;
    }
}

.priceListButton button {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    font-size: 20px !important;
    height: 26px !important;
    width: 100% !important;
    
}

.userInfoCol {
    max-width: 27.33333%;
}

.userInfo {
    font-weight: 600 !important;
    line-height: 5px !important;
    padding-left: 2%;
}

.userName {
    line-height: 16px !important;
    font-size: 12px !important;
}

@media (max-width: 370px) {
    .userName {
        font-size: 11px !important;
    }
}

.userTgName {
    font-size: 10px !important;
}

.shoppingCart {
    padding-right: 5px;
}

.itemCount {
    font-family: "Gilroy-Bold" !important;
    font-weight: 700 !important;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #414141; 
    color: #F3F3F3; 
    border-radius: 50%;
    width: 18px;
    height: 18px;
    padding-left: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center !important; 
    font-size: 10px;
  }

.menuButton {
    padding-right: 15px;
}
