.pageContainer {
    font-family: "Gilroy-Regular" !important; 
    max-width: 100%;
    margin: 0 auto;
    margin-top: 70px !important;
    padding: 16px;
    background-color: #161616;
    overflow: hidden !important;
    position: relative !important;
    min-height: 100vh;
}

.goodsListContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
}

.paragraphHeader {
    display: inline-block !important;
    font-size: 24px !important;
    font-family: "Gilroy-Bold" !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.5px !important;
    background: -webkit-linear-gradient(328deg, #E8C900 4.27%, #ED8000 139.4%);
}

.paragraph {
    font-size: 14px !important;
    font-family: "Gilroy-Regular" !important;
    font-weight: 400 !important;
    color: gray;
    margin-bottom: 10px;
}

.placeOrderContainer {
    position: absolute;
    bottom: 20px;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px;
    color: white;
    margin-right: 15px;
}

.placeOrderText {
    width: 70%;
    padding: 15px;
}

.placeOrderText > span {
    font-family: "Gilroy-regular" !important;
    letter-spacing: -0.5px !important;
}

.palceOrderButton {
    width: 30%;
}

.palceOrderButton > button {
    width: 90% !important;
    height: 40px !important;
    font-size: 20px !important;
    text-wrap: nowrap !important;
}