/* HomePage.module.scss */
.pageContainer {
    font-family: "DrukCyr-Medium" !important; 
    max-width: 100%;
    margin: 0 auto;
    margin-top: 70px !important;
    padding: 16px;
    background-color: #161616;
    overflow: hidden !important;
    position: relative !important;
    min-height: 100vh;
}

.yellowCircle {
    position: absolute;
    width: 140px;
    height: 140px;
    background: linear-gradient(98.15deg, #E8C900 -4.27%, #ED8000 139.4%);
    filter: blur(80px);
    opacity: 0.9;

}
  
.circleOne {
    top: 10%;
    right: -10%;
}

.circleTwo {
    top: 30%;
    left: -20%;
    opacity: 0.5;
}

.catalogButton {
    display: flex !important;
    justify-content: center !important;

}

.catalogButton button {
    width: 70% !important;
    font-size: 32px !important;
    text-decoration: none !important;
}

.categoryTilesBlock {
    background-color: #E8E8E8 !important;
    border-radius: 10px;
    padding-bottom: 5px;
    margin-top: 15px;
    position: relative;
    z-index: 100 !important;
}
  
.categoryTile {
    height: 155px;
    background: linear-gradient(98.15deg, #E8C900 -4.27%, #ED8000 139.4%);
    margin: 10px;
    font-family: "Gilroy-Regular" !important;
}

.categoryTile h6 {
    font-weight: 600 !important;
    font-size: 16px !important;
    letter-spacing: -0.3px;
}