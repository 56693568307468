@font-face {
  font-family: 'DrukCyr-Medium';
  src: url('./assets/fonts/DrukCyr/DrukCyr-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('./assets/fonts/Gilroy/Gilroy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('./assets/fonts/Gilroy/Gilroy-Bold.ttf') format('truetype');
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: 'DrukCyr-Medium' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  margin: 0 auto !important;
  padding: 0 !important;
  font-family: 'Roboto', sans-serif;
  background-color: #f0f0f0;
  font-weight: 500 !important;
}

span, h1, h2, h3, h4, div, button {
  font-family: "DrukCyr-Medium" !important; 
  font-weight: 500 !important;
  letter-spacing: 1px !important;
}

p, h5, h6 {
  font-family: "Gilroy-Regular" !important; 
  font-weight: 500 !important;
  letter-spacing: -0.3px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
