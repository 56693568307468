.cardContainer {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    max-width: 470px;
    height: 70px;
}

.goodsCard {
    height: inherit!important;
    display: flex!important;
    flex-direction: row!important;
    align-items: center!important;
    background-color: #1d1d1d!important;
    border-radius: 10px!important;
    color: white!important;
}

.cardInner {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px;
}

.productImage {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    margin-right: 0px;
    position: relative;
}

.productImage img {
    border-radius: 6px;
    height: 50px;
}

.cardContent {
    flex-grow: 1 !important;
    padding: 0px !important;
    padding-left: 10px !important;
}

.productTitle {
    font-size: 13px !important;
    font-weight: 500 !important;
}

.productQuantity {
    font-family: "Gilroy-Bold" !important;
}

.quantityToBuySection {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0.5px solid #3C3C3C;
    outline-offset: -4px;
    border-radius: 8px;

}

.productQuantityToBuy {
    font-size: 16px  !important;
    color: white  !important;
    padding-top: 1px  !important;
}

.removeButton {
    padding-top: 0px  !important;
    padding-bottom: 0px  !important;
    padding-right: 5px  !important;
    padding-left: 8px  !important;
}

.addButton {
    background: linear-gradient(98.15deg, #E8C900 -4.27%, #ED8000 139.4%);
    border-radius: 5px  !important;
    padding: 4px  !important;
    margin-left: 10px  !important;
    height: 32px  !important;
    width: 32px  !important;
}

.addButton:hover {
    background-color: #ff9500;
}

.fireIcon {
    position: absolute;
    top: -7px;
    left: -7px;
    width: 20px; 
    height: 20px; 
    background-color: #DF3838; 
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fireIcon img {
    width: 15px;
    height: 15px;
    padding-top: 1px;
    padding-bottom: 2px;
    padding-right: 1px;
}

.deleteButton {
    margin-right: 5px;
}
.deleteButton img {
    width: 22px;
    height: 22px;
    margin-bottom: 2px;
}