.pageContainer {
    font-family: "Gilroy-Regular" !important; 
    max-width: 100%;
    margin: 0 auto;
    margin-top: 70px !important;
    padding: 16px;
    background-color: #161616;
    overflow: hidden !important;
    position: relative !important;
    min-height: 100vh;
}

.filterButton {
    width: 34px;
    height: 28px;
    background: linear-gradient(98.38deg, #E8C900 -5.95%, #EC9100 53.67%, #EDAB00 99.21%);
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.goodsListContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;
}

.paragraphHeader {
    display: inline-block;
    font-size: 24px  !important;
    font-family: "Gilroy-Bold" !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.5px !important;
    background: -webkit-linear-gradient(328deg, #E8C900 4.27%, #ED8000 139.4%);
}

.paragraph {
    font-size: 14px  !important;
    font-family: "Gilroy-Regular" !important;
    font-weight: 400 !important;
    color: gray;
}