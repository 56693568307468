
.link {
  font-family: "Gilroy-Regular" !important;
  color: #F3F3F3!important;
  text-decoration: none;
  line-height: 20px !important;
  letter-spacing: -0.5px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.currentDestination {
  color: grey!important;
  text-decoration: none;
  line-height: 20px !important;
  letter-spacing: -0.5px !important;
  font-size: 16px !important;
}

a, div {
  font-family: "Gilroy-Regular" !important;
}