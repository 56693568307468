.filterButton {
    width: 34px;
    height: 28px;
    background: linear-gradient(98.38deg, #E8C900 -5.95%, #EC9100 53.67%, #EDAB00 99.21%);
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.toolbar {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.fullscreenDrawer {
    width: 100% !important;
    height: calc(100vh - 76px) !important;
    top: 76px !important;
    background-image: url("../../../assets/images/MenuBackground.png");
    align-content: start !important;
    justify-content: left !important;
    align-items: start !important;
}

.menuDrawer {
    color: #F3F3F3;
    height: 100%;
    width: 100% !important;
    display: flex !important;
    flex-direction: column;
    align-items: flex-start !important;
    align-self: flex-end;
}

.menuDrawer ul {
    width: 100% !important;
}

.menuDrawer span {
    font-family: "Gilroy-Regular" !important;
    font-weight: 600 !important;
    text-align: right;
    font-size: 18px; 
    margin-top: 0px;
    margin-bottom: 0px;
    letter-spacing: 0.5px !important;
}

.menuDrawer p {
    font-weight: 600 !important;
    letter-spacing: 0.5px !important;
}

.filterCategory {
    background: linear-gradient(98.15deg, #E8C900 -4.27%, #ED8000 139.4%);
    padding: 10px 16px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.filterCategory p {
    font-size: 22px !important;
    color: #43361C !important;
    padding-left: 14px !important;
}

.filterCategoryTitle {
    font-size: 16px;
    font-weight: bold;
}

.filterCategoryContent {
    padding: 16px;
}

.listItem {
    display: flex;
    align-items: center;
    width: auto !important;
    height: auto;
}

.applyFilter button {
    width: 80% !important;
}



