.orangeButton {
    width: 100%!important;
    min-width: 100%;
    color: #F3F3F3!important;
    padding-top: 20px!important;
    padding-bottom: 20px!important;
    font-family: 'DrukCyr-Medium';
    font-size: 24px!important;
    font-weight: 500!important;
    line-height: 14.4px!important;

    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    text-align: center!important;

    background: linear-gradient(98.38deg, #E8C900 -5.95%, #EC9100 53.67%, #EDAB00 99.21%);
    border-radius: 8px !important;

    font-weight: bold;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    
    &:hover {
        background: linear-gradient(98.38deg, #E8C900 -5.95%, #EC9100 53.67%, #EDAB00 99.21%);
    }

}
.orangeButton:hover {
    @media (min-width: 800px) {
        content: "";
        color: 'white'!important;
        background-color: transparent!important;
        border: 1px solid white!important;
        transition: background-color .3s, color .3s, border .3s!important; 
    }
}
.orangeButton:disabled {
    opacity: 0.5;
    color: #999!important;
    border: 1px solid #ccc!important;
    background-color: #eee!important;
    pointer-events: none;
}