.cardContainer {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    width: 100% !important;
    max-width: 470px !important;
    height: 200px !important;
}

.priceCard {
    height: inherit !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    background-color: #1d1d1d !important;
    border-radius: 10px !important;
    color: white !important;
}

.cardInner {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
    height: 100% !important;
}

.productImage {
    min-width: 40%;
    width: 40%;
    height: 100%;
    border-radius: 6px;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #303030;

}

.productImage img {
    width: auto !important;
    max-width: 140px !important;
    height: 140px !important;
    margin-bottom: 5px !important;
}

.cardContent {
    padding: 0px !important;
    height: 100% !important;
    max-width: 60% !important;
    width: 60% !important;
}

.productTitle {
    font-size: 14px !important;
    font-family: "Gilroy-Bold" !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    letter-spacing: -0.5px !important;
    background: -webkit-linear-gradient(328deg, #E8C900 4.27%, #ED8000 139.4%);
}

.productPuffCount {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #F3F3F3 !important;
}

.productQuantity {
    font-size: 12px !important;
    color: gray !important;
}

td, th {
    font-family: "Gilroy-Regular" !important; 
    font-weight: 500 !important;
    letter-spacing: -0.3px !important;
}

.tableContainer {
    background-color: transparent !important;
    height: inherit !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    align-content: center !important;
}

.tableHeader {
    font-size: 14px !important;
    font-family: "Gilroy-Bold" !important;
    -webkit-background-clip: text !important;
    background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    letter-spacing: -0.5px !important;
    background: -webkit-linear-gradient(328deg, #E8C900 4.27%, #ED8000 139.4%);
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 3px !important;
    border-bottom: 1px solid #3A3A3A !important;
}

.tableCell {
    font-size: 12px !important;
    color: white !important;
    background-color: transparent !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    border-bottom: 1px solid #3A3A3A !important;
}

.priceRow:last-child > .tableCell {
    border-bottom: 0 !important;
}

